export const isUkraine = (number) => {
  if (!number) return false;
  const ukrOperators = [
    "067",
    "068",
    "096",
    "097",
    "098",
    "050",
    "066",
    "095",
    "099",
    "063",
    "073",
    "093",
    "091",
    "092",
    "089",
    "094",
    "075",
  ];
  return (
    ukrOperators.includes(number.slice(2, 5)) && number.slice(0, 2) === "38"
  );
};

export const readyToPasteNumber = (number) => {
  let index = number.indexOf("0");
  if (number[index - 1] === "5") index = -1;
  let num = number.slice(index + 1);
  return num;

  // if (num.length > 7) {
  //   return `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(
  //     5,
  //     7
  //   )} ${num.slice(7, 9)}`;
  // } else if (num.length > 5) {
  //   return `${num.slice(0, 2)} ${num.slice(2, 5)} ${num.slice(5, 7)}`;
  // } else if (num.length > 2) {
  //   return `${num.slice(0, 2)} ${num.slice(2, 5)}`;
  // } else {
  //   return `${num.slice(0, 2)}`;
  // }

  // return `${num.slice(0, 2)}${num.length > 2 && ' ' + num.slice(2, 5)}${num.length > 5 && ' ' + num.slice(5, 7)}${num.length > 7 && ' ' + num.slice(7, 9)}`
};

export const is044 = (number) => {
  if (!number) return false;
  return number.slice(2, 5) === "044";
};

export function filteredLogin(val) {
  if (!val) return "";
  val = val.replace(/[^A-Za-z0-9'‎\u2018\u2019‎ @.-]/g, "");
  return val.replace(/[\u2018\u2019‎]/g, "'");
}

export function filteredName(val) {
  if (!val) return "";
  val = val.replace(/[^А-Яа-яІіЇїҐґЄє\sʼ'`‎\u2018\u2019‎ -]/g, "");
  // if (val) val = val[0].toUpperCase() + val.slice(1).toLowerCase();
  val = replaceBeforeSubmit(val);

  if (val) {
    val = val
      .split(" ")
      .map((word) => {
        if (word.includes("-")) {
          return word
            .split("-")
            .map(
              (word) =>
                word && word[0].toUpperCase() + word.slice(1).toLowerCase()
            )
            .join("-");
        } else
          return word && word[0].toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  }
  return val;
}

export function filteredLatName(val) {
  if (!val) return "";
  val = val.replace(/[^A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s‎\u2018\u2019‎ -]/g, "");
  // if (val) val = val[0].toUpperCase() + val.slice(1).toLowerCase();
  val = replaceBeforeSubmit(val);
  if (val) {
    val = val
      .split(" ")
      .map((word) => {
        if (word.includes("-")) {
          return word
            .split("-")
            .map(
              (word) =>
                word && word[0].toUpperCase() + word.slice(1).toLowerCase()
            )
            .join("-");
        } else
          return word && word[0].toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  }
  return val;
}

export const replaceBeforeSubmit = (str) => {
  return str
    .replace(/\s{2,}/g, " ")
    .replace(/-{2,}/g, "-")
    .replace(/^[-{1,}]/g, "")
    .replace(/^[\s{1,}]/g, "")
    .replace(/'{2,}/g, "'")
    .replace(/`{1,}/g, "'")
    .replace(/ʼ{1,}/g, "'")
    .replace(/‘{1,}/g, "'")
    .replace(/’{1,}/g, "'")
    .replace(/^[‘’ʼ'`{1,}]/g, "")
    .replace(/ -/g, " ")
    .replace(/- /g, "-");
};
export const replaceAfterSubmit = (str) => {
  return str
    .trim()
    .replace(/'/g, "'")
    .replace(/`/g, "'")
    .replace(/ʼ/g, "'")
    .replace(/‘/g, "'")
    .replace(/’/g, "'")
    .replace(/[-{1,}]$/g, "")
    .replace(/['{1,}]$/g, "");
};

let forbiddenWords = [
  "підпис",
  "агент",
  "представник",
  "представитель",
  "неопр",
  "контакт",
  "керівник",
  "фоп",
  "тов",
  "канцелярія",
  "канцелярия",
  "пат",
  "спд",
  "доверенность",
  "довіреність",
  "тест",
  "бухгалтер",
  "магазин",
  "персонал",
];
export let wrongPattern = /(.)\1{2}|^['-]{1}|['-]{1}$|['-]{2}/;

export function isWrongFullName(fullName) {
  let words = fullName.toLowerCase().split(/[+]+/);
  let uniqueWords = words.filter((v, i, a) => a.indexOf(v) === i);
  if (
    words.length < 2 ||
    words.length > 5 ||
    words.length - uniqueWords.length > 1
  )
    return true;
  return words.some(isWrongName);
}

export function isWrongName(name) {
  let word = name.toLowerCase().trim();
  if (window.AppLocation === "ua") {
    if (
      !/^[А-яІіЇїҐґЄє\s'-]+$/.test(name) ||
      forbiddenWords.indexOf(word) >= 0
    ) {
      return true;
    }
    return false;
  } else {
    if (
      !/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s'-]+$/.test(name) ||
      forbiddenWords.indexOf(word) >= 0
    ) {
      return true;
    }
  }
}

export function isWrongNameWord(name) {
  let wrong = false;
  let forbiddenWord = false;
  if (name) {
    forbiddenWords.forEach((el) => {
      name.split(/\-|\s/).forEach((w) => {
        if (el === w.toLowerCase()) forbiddenWord = true;
      });
    });
    name
      .toLowerCase()
      .split("")
      .reduce((acc, w, p, a) => {
        if (w === a[p - 1]) {
          if (acc + 1 > 2) wrong = true;
          return acc + 1;
        }
        return 0;
      }, 0);
  }
  return forbiddenWord || wrong ? true : false;
}

export const isWrongNameLength = (str, type) => {
  const len = str?.split(/\-|\s/).length;
  return type === "mid_name" ? len <= 1 : len <= 2;
};

export function isEmailOrPhone(value) {
  if (/^[0-9a-z-_.]+@[0-9a-z-.]+\.[a-z]{2,}$/i.test(value)) return "email";
  else if (/^\d{12}$/i.test(value) && isUkraine(value)) return "phone";
  else return "";
}

export function browserLang() {
  let lang;

  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    lang = navigator.userLanguage;
  } else if (navigator.language[0]) {
    // IE only
    lang = navigator.language[0];
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = navigator.language;
  }

  return lang;
}

export function detectionBrowser() {
  function cBrowser() {
    const ua = navigator.userAgent;
    const bName = (function () {
      if (ua.search(/MSIE/) > -1) return "ie";
      else if (ua.search(/Firefox/) > -1) return "firefox";
      else if (ua.search(/Opera/) > -1) return "opera";
      else if (ua.search(/Chrome/) > -1) return "chrome";
      else if (ua.search(/CriOS/) > -1) return "crios";
      else if (ua.search(/Safari/) > -1 && ua.indexOf("Version/") > -1)
        return "safari";
      else if (ua.search(/Konqueror/) > -1) return "konqueror";
      else if (ua.search(/Iceweasel/) > -1) return "iceweasel";
      else if (ua.search(/SeaMonkey/) > -1) return "seamonkey";
      else return "other";
    })();
    const version = (function (bName) {
      switch (bName) {
        case "ie":
          return ua.split("MSIE ")[1].split(";")[0];
          break;
        case "firefox":
          return ua.split("Firefox/")[1];
          break;
        case "opera":
          return ua.split("Version/")[1];
          break;
        case "chrome":
          return ua.split("Chrome/")[1].split(" ")[0];
          break;
        case "crios":
          return ua.split("CriOS/")[1].split(" ")[0];
          break;
        case "safari":
          return ua.split("Version/")[1].split(" ")[0];
          break;
        case "konqueror":
          return ua.split("KHTML/")[1].split(" ")[0];
          break;
        case "iceweasel":
          return ua.split("Iceweasel/")[1].split(" ")[0];
          break;
        case "seamonkey":
          return ua.split("SeaMonkey/")[1];
          break;
        case "other":
          return "";
      }
    })(bName);
    return {
      name: bName,
      version: version.split(".")[0],
      fullVersion: bName + version,
    };
  }

  return cBrowser();
}
