import { createApp } from "vue";
import { createPinia } from "pinia";
import { Quasar, Dialog } from "quasar";
import i18n from "@/plugins/i18n";
import * as Sentry from "@sentry/vue";
import vue3GoogleLogin from "vue3-google-login";
// import * as locales from "@/locales";

import App from "./App.vue";
import router from "./router";

import "@quasar/extras/material-icons/material-icons.css";
import "quasar/src/css/index.sass";
import "./scss/main.scss";

window.AppLocation = import.meta.env.VITE_LOCATION || "ua";

const app = createApp(App);

app.use(Quasar, {
  plugins: { Dialog },
  config: {
    dark: false,
  },
  animations: "all",
});
app.use(i18n);
app.use(createPinia());
app.use(router);

app.use(vue3GoogleLogin, {
  clientId: window.location.href.includes("localhost")
    ? import.meta.env.VITE_GOOGLE_CLIENT_ID_DEV
    : import.meta.env.VITE_GOOGLE_CLIENT_ID_PROD,
  idConfiguration: {
    use_fedcm_for_prompt: true,
  },
});

Sentry.init({
  app,
  dsn: "https://e9a9b47f6c9af6dfd71ac5252a0ca98c@o4507968061243392.ingest.de.sentry.io/4507968064585808",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount("#app");

export default app;
