const uk = {
  accept: "Зрозуміло",
  next: "Продовжити",
  back: "Назад",
  skip: "Пропустити",
  language: "Мова",
  loading: "Завантаження...",

  // Errors
  wrongRepeat: "Паролі не збігаються",
  requireField: "Це обов'язкове поле",
  errorFioField: "Перевірте правильність введених даних",
  pinMust4: "Має містити від 4 символів",
  pinMust6: "Має містити від 6 символів",
  must4: "Повинно бути 4 цифри",
  must6: "Повинно бути 6 цифр",
  must9: "Повинно бути 9 цифр",
  protectionPhoneMainError: "Збігається з основним номером",
  mustBe: "Повинно бути ",
  mustBeEnd: " цифр",
  unknownOperator: "Тільки мобільні номери",
  contactRepeats: "Новий контакт співпадає з вказаним раніше",
  errorOldBrowserTitle: "Оновіть браузер",
  errorOldBrowserText:
    "Наразі ви використовуєте стару версію браузера. Будь ласка, оновіть браузер для продовження",
  enterEmailError: "Неправильна електронна адреса",
  errorPageTitle502: "Сервіс тимчасово недоступний",
  errorPageText502: "Спробуйте підключитись трохи пізніше",
  latNameError: "Будь ласка заповніть латиницею",
  wrongOtp: "Ви ввели неправильний код. Залишилось {attempts} спроби(а)",
  wrongPassword:
    "Ви ввели неправильний пароль. Залишилось {attempts} спроби(а)",

  // Phone input
  inputCountry: "Країна",
  inputCountryName: "Назва країни",
  inputFound: "Результати пошуку",
  inputNotFound: "Ми нічого не знайшли за запитом <span>”{text}”</span>",

  // Email input
  emailInputLabel: "Email",

  // Start
  startTitle: "Вітаю в Новій пошті!", // new
  enterTitle: "Вкажіть номер телефону", // new
  enterEmailTitle: "Вкажіть електронну пошту", // new
  enterAcceptBtn: "Надіслати код", // new
  enterText:
    "Ви можете увійти або створити акаунт, якщо ви вперше в Nova Post.", // new
  enterOrRegistration: "Вхід",
  shouldRegistration:
    "Увійдіть та отримуйте доступ до розширених налаштувань, замовлення додаткових послуг та сплати за них онлайн.",
  enterOr: "або",
  enterPopupBtnEmail: "Увійти з email",
  enterPopupBtnPhone: "Увійти за номером телефону", // new

  // Social
  socialAppleBtn: "Продовжити з Apple",
  socialGoogleBtn: "Продовжити з Google",
  socialDeclinePopupTitle: "Неможливо додати обліковий запис",
  socialDeclinePopupText:
    "На жаль, такого облікового запису немає. Авторизуйтесь за допомогою номера телефону, після чого додайте ваш обліковий запис Google або Apple в налаштуваннях профілю.",
  socialDeclinePopupBtn: "Зрозуміло",

  // Enter Otp
  enterOtpTitle: "Введіть код із SMS", // updated
  enterOtpTitleEmail: "Введіть код з email", // new
  enterOtpTextPhone:
    "Ми надіслали код на номер <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Ми надіслали код на email <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle: "Вкажіть номер телефону, щоб увійти з {process}", // new
  enterOtpSocialText:
    "Номер телефону потрібен для першого входу через {process}, щоб відобразити інформацію про ваші посилки. При наступному вході вводити номер телефону буде не потрібно.", // new
  enterOtpCalling: "Ми вам дзвонимо",
  resendOtp: "Надіслати код ще раз", // updated
  resendOtpByCall: "Краще зателефонуйте",
  resendOtpAfter: "Відправити ще раз через 0:{time}",
  enterOtp502Title: "На жаль, вказаний номер не відповідає",
  enterOtp502Text: "Можливо, через поганий зв’язок. Спробуйте ще раз",

  // Enter password
  enterPasswordTitle: "Введіть пароль",
  enterPasswordText: "Цей пароль ви вказували під час реєстрації",
  enterPasswordLabel: "Пароль",
  enterPasswordForgot: "Забули пароль?",
  passwordPopupTitle: "Неправильний пароль",
  passwordPopupText:
    "Ви або хтось інший кілька разів ввів неправильний пароль.Для захисту ми заблокували профіль.",
  passwordPopupBtn1: "Повернутись на початковий екран",

  // Create password
  createPasswordTitle: "Створіть новий пароль",
  createPasswordText:
    "Для номеру <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Пароль",
  createPasswordInput1Hint: "Має містити від 6 символів",
  createPasswordInput2Label: "Повторіть пароль",

  // Registration
  registrationTitle: "Створити профіль",
  registrationText:
    "Для реєстрації введіть свій номер телефону та створіть пароль",
  registrationInput1Label: "Пароль",
  registrationInput1Hint: "Має містити щонайменше 6 символів",
  registrationInput2Label: "Повторіть пароль",
  registrationPolicy: `Продовжуючи я підтверджую що ознайомлений(а) з <a class="text-primary" href="{link}" target="_blank">Політикою конфіденційності</a> та даю згоду на обробку моїх персональних даних`,
  registrationPolicy1: `Продовжуючи я підтверджую що ознайомлений(а) з`,
  registrationPolicy2: `Політикою конфіденційності`,
  registrationPolicy3: `та даю згоду на обробку моїх персональних даних`,
  registration: "",

  // Full name
  fullNameTitle: "Створіть профіль", // updated
  fullNameText: "Для отримання та відправлення посилок.", // updated
  fullNameFirst: "Ім’я",
  fullNameLast: "Прізвище",
  fullNameMiddle: "По батькові",
  fullNameCheckbox: "Немає по батькові в документах",
  fullNameBtn: "Зареєструватись", // new

  // Create recovery
  createRecoveryTitle: "Захистіть свій профіль",
  createRecoveryText:
    "Заповніть один із додаткових контактів (ваш email або додатковий номер), щоб за потреби швидше відновити свій профіль.",
  createRecoveryHint:
    "Номер телефону не повинен збігатися з введеним під час реєстрації",

  // Choose recovery
  chooseRecoveryTitle: "Відновлення пароля",
  chooseRecoveryText: "Оберіть контакт та чекайте код підтвердження",
  chooseRecoveryGreyBtn: "Не можу отримати код",

  // Profile blocked
  profileBlockedTitle: "Тимчасово обмеженодоступ до застосунку", // updated
  profileBlockedText:
    "Ви використали усі спроби для введення коду. Спробуйте знову через 10 хвилин.", // updated
  profileBlockedTimer:
    "Cпробуйте ще через: <span class='no-wrap text-black text-weight-medium'>{time}</span>", // new
  profileBlockedMinutes:
    "Профіль розблокується через: <span class='no-wrap text-black text-weight-medium'>{minutes} хв</span>",
  profileBlockedBtn: "Зрозуміло",

  // Recovery not found
  recoveryNotFoundTitle: "Зареєструйте новий профіль",
  recoveryNotFoundText1:
    "Ви не внесли додаткові контакти, тому ми не можемо відновити ваш доступ до профілю за номером: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Будь ласка, зареєструйтесь знову за цим же номером. Інформація про ваші посилки залишиться, але накопичені бали ми не зможемо зберегти",
  recoveryNotFoundBtn: "Зареєструватись",
  recoveryNotNowBtn: "Не зараз",

  recoveryNotFoundText1Another:
    "У вас відсутній доступ до контактів, тому ми не можемо відновити ваш доступ до профілю за номером: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Пароль змінено",
  passwordChangedText1:
    "Використовуйте пароль тільки на сайті novapost.com та у додатку.",
  passwordChangedText2:
    "Не повідомляйте пароль нікому. Наші співробітники його ніколи не питають",
  passwordChangedBtn: "Зрозуміло",

  // Error limit 10
  errorLimit10PageTitle: "Ваш номер тимчасово заблоковано",
  errorLimit10PageTextWithNumber:
    "Нам не вдалось підтвердити ваш номер по коду, або через нестабільний зв'язок ваші повідомлення не доставили.",
  errorLimit10PageTimer:
    "Будь ласка, спробуйте ще через: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Повернутись на початковий екран",

  // Error Reg Limit
  errorRegLimitTitle: "Спробуйте пізніше",
  errorRegLimitText:
    "Кількість реєстрацій за одним номером обмежена. Будь ласка, спробуйте зареєструватись через 4 години",
  errorRegLimitBtn: "Повернутись на початковий екран",

  // Error unforeseen
  errorUnforeseenTitle: "Сталася помилка",
  errorUnforeseenText: "Повторіть спробу, щоб увійти",
  errorUnforeseenBtn: "Зрозуміло",

  // Bot dialog
  botDialogTitle: "Сталася помилка",
  botDialogText: "Повторіть спробу, щоб увійти",
  botDialogBtn: "Зрозуміло",

  // Email for login not found
  emailNotFoundTitile: "На жаль профіль за таким email відсутній",
  emailNotFoundText: "Необхідно авторизуватись за номером телефону",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Дана опція доступна тільки представникам юридичних осіб",
  loginByEmailDeclinedText: "Необхідно авторизуватись за номером телефону",
};

export default uk;
