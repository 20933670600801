<template>
  <q-dialog
    :position="$q.screen.lt.md ? 'bottom' : 'standard'"
    ref="dialogRef"
    v-model="dialogIsOpen"
    @hide="eventHandler()"
    :persistent="persistent"
  >
    <q-card
      class="card"
      :class="{ full }"
      :style="bgColor ? `background-color: ${bgColor}` : ''"
    >
      <q-btn
        color="primary"
        icon="close"
        class="close"
        @click="eventHandler()"
        round
        flat
      />
      <q-card-section
        class="flex column items-center justify-center full-height text-center no-wrap"
      >
        <img :src="image" class="image" />
        <h2 class="title" v-html="title"></h2>
        <p
          :class="['text', { 'q-mb-md': timer || i !== text.length - 1 }]"
          v-for="(t, i) in text"
          :key="`text${i}`"
          v-html="t"
        ></p>
        <p
          class="text"
          v-if="timer"
          v-html="t('profileBlockedTimer', { time: htmlTime })"
        ></p>
        <p
          class="text"
          v-if="minutes"
          v-html="t('profileBlockedMinutes', { minutes })"
        ></p>
        <base-btn
          :class="{ 'q-mb-md': props.btnCencel }"
          :label="props.btnAccept || t('accept')"
          @click="eventHandler(true)"
        />
        <base-btn
          :label="props.btnCencel"
          @click="
            btnCencelHandler ? btnCencelHandler() : onDialogHide(),
              (hasAccepted = true)
          "
          v-if="props.btnCencel"
          color="transparent"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import BaseBtn from "@/components/ui/BaseBtn.vue";
import { ref, computed, onMounted } from "vue";
import { useDialogPluginComponent } from "quasar";
import { useI18n } from "vue-i18n";

const { t } = useI18n({});

const props = defineProps({
  title: String,
  image: String,
  text: Array,
  persistent: Boolean,
  closeForCancel: Boolean,
  timer: Boolean,
  full: Boolean,
  bgColor: String,
  minutes: {
    type: Number,
    default: 0,
  },
  btnAccept: {
    type: String,
  },
  btnCencel: {
    type: String,
    default: "",
  },
  btnCencelHandler: {
    type: Function,
  },
});

defineEmits([...useDialogPluginComponent.emits]);
const { dialogRef, onDialogOK, onDialogCancel, onDialogHide } =
  useDialogPluginComponent();

const dialogIsOpen = ref(false);

const interval = ref(null);
const counter = ref("");
const hasAccepted = ref(false);
const timeend = ref(new Date(new Date(new Date().getTime() + 600000)));

const htmlTime = computed(() => {
  return (
    (counter.value.min < 10 ? "0" + counter.value.min : counter.value.min) +
    ":" +
    (counter.value.sec < 10 ? "0" + counter.value.sec : counter.value.sec)
  );
});
const time = () => {
  let today = new Date();
  today = Math.floor((timeend.value - today) / 1000);
  let tsec = today % 60;
  today = Math.floor(today / 60);
  let tmin = today % 60;
  today = Math.floor(today / 60);
  let thour = today % 24;
  today = Math.floor(today / 24);

  return { min: tmin, sec: tsec };
};

const eventHandler = (value) => {
  if (!hasAccepted.value) {
    if (props.closeForCancel && !value) onDialogCancel();
    else {
      onDialogOK();
      hasAccepted.value = true;
    }
  }
};

onMounted(() => {
  if (props.timer) counter.value = time();
  interval.value = setInterval(() => {
    if (counter.value.min + counter.value.sec <= 0) {
      clearInterval(interval.value);
      counter.value = { min: 0, sec: 0 };
      onDialogOK();
      return;
    }
    counter.value = time();
  }, 1000);
});
</script>

<style lang="scss" scoped>
.card {
  // height: 90vh;
  min-height: 66vh;
  width: 100vw;
  border-radius: 16px 16px 0 0;
  background-color: $popup;
  // padding-bottom: 34px;
}
.close {
  position: absolute;
  right: 10px;
  top: 25px;
  z-index: 3;
}
.title {
  font-size: 24px;
  font-weight: 600;
  white-space: normal;
}
.text {
  white-space: normal;
}
.image,
.title,
.text {
  margin-bottom: 16px;
}
.image {
  width: 200px;
}
@media screen and (min-width: 400px) {
  .card {
    padding-bottom: 34px;
  }
  .image {
    margin-bottom: 24px;
  }
  .text {
    margin-bottom: 32px;
    &.q-mb-md {
      margin-bottom: 16px;
    }
  }
}
@media screen and (max-width: 599px) {
  .card.full {
    min-height: 100vh;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 826px) {
  .card {
    width: 486px;
    padding: 16px;
    min-height: auto;
    border-radius: 16px;
  }
}
</style>
