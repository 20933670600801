import { defineStore } from "pinia";

export const useUiStore = defineStore("ui", {
  state: () => ({
    accessRedirect: "",
    hasError: false,
    errorCode: 0,
    errorText: "",
    errorRedirect: "",
    isDarkMode: false,
    featureMode: false,
  }),
  getters: {
    // challenge: (state) => state.challenge,
  },
  actions: {
    increment() {},
  },
});
