const ro = {
  accept: "Am înțeles",
  next: "Continuați",
  back: "Înapoi",
  skip: "Treceți peste",
  language: "Limba",
  loading: "Se încarcă...",

  // Errors
  wrongRepeat: "Parolele nu coincid",
  requireField: "Acest câmp este obligatoriu",
  errorFioField: "Vă rugăm să verificați corectitudinea datelor introduse",
  pinMust4: "Trebuie să conțină cel puțin 4 simboluri",
  pinMust6: "Trebuie să conțină cel puțin 6 simboluri",
  must4: "Trebuie să conțină 4 cifre",
  must6: "Trebuie să conțină 6 cifre",
  must9: "Trebuie să conțină 9 cifre",
  protectionPhoneMainError: "Coincide cu numărul principal",
  mustBe: "Ar trebui să conțină",
  mustBeEnd: "cifre",
  unknownOperator: "Doar numere de telefon mobil",
  contactRepeats: "Noul contact coincide cu cel specificat anterior",
  errorOldBrowserTitle: "Actualizați browserul",
  errorOldBrowserText:
    "Momentan folosiți versiunea veche a browserului. Vă rugăm să actualizați browserul pentru a continua ",
  enterEmailError: "Adresa de e-mail este incorectă",
  errorPageTitle502: "Serviciul este temporar indisponibil",
  errorPageText502: "Încercați să vă conectați mai târziu",
  latNameError: "Vă rugăm să completați cu litere latine",
  wrongOtp: "Ați introdus codul incorect. Au rămas {attempts} încercări",
  wrongPassword: "Ați introdus parola incorectă. Au rămas {attempts} încercări",

  // Phone input
  inputCountry: "Țara",
  inputCountryName: "Denumirea țării",
  inputFound: "Rezultatele căutării",
  inputNotFound:
    "Noi nu am găsit nimic conform solicitării <span>”{text}”</span>",

  // Email input
  emailInputLabel: "Adresa de e-mail",

  // Start
  startTitle: "Bine ați venit la Nova Post!",
  enterTitle: "Introduceți numărul de telefon",
  enterEmailTitle: "Introduceți adresa de e-mail",
  enterAcceptBtn: "Trimite codul",
  enterText:
    "Vă puteți conecta sau crea un cont dacă sunteți nou la Nova Post.",
  enterTextEmail: "Pentru a vă conecta, introduceți adresa dvs. de email",
  enterOrRegistration: "Autentificare",
  shouldRegistration:
    "Autentificați-vă și accesați setările avansate, comandați servicii suplimentare și plătiți pentru ele online",
  enterOr: "sau",
  enterPopupBtnEmail: "Conectează-te cu email",
  enterPopupBtnPhone: "Conectați-vă cu numărul de telefon",

  // Social
  socialAppleBtn: "Continuă cu Apple",
  socialGoogleBtn: "Continuă cu Google",
  socialDeclinePopupTitle: "Nu se poate autoriza",
  socialDeclinePopupText:
    "Din păcate, nu există un astfel de cont. Autentificați-vă folosind numărul dvs. de telefon și apoi adăugați contul dvs. Google sau Apple în setările profilului.",
  socialDeclinePopupBtn: "Înțeles",

  // Enter Otp
  enterOtpTitle: "Introduceți codul din SMS",
  enterOtpTitleEmail: "Introduceți codul din e-mail",
  enterOtpTextPhone:
    "Codul pentru confirmare a fost trimis la numărul <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Codul de confirmare a fost trimis pe adresa de e-mail <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle:
    "Introduceți numărul dvs. de telefon pentru a vă conecta cu {process}",
  enterOtpSocialText:
    "Numărul de telefon este necesar pentru prima conectare cu {process} pentru a afișa informații despre coletele dvs. La conectările ulterioare, nu va fi necesar să introduceți numărul de telefon.",
  enterOtpCalling: "Vă contactăm",
  resendOtp: "Trimiteți din nou codul",
  resendOtpByCall: "Prefer să fiu telefonat",
  resendOtpAfter: "Trimiteți încă o dată cu 0:{time}",
  enterOtp502Title: "Cu părere de rău, numărul indicat nu răspunde",
  enterOtp502Text:
    "Ar putea fi o problemă de conexiune. Încercați încă o dată ",

  // Enter password
  enterPasswordTitle: "Introduceți parola",
  enterPasswordText: "Ați creat această parolă în momentul înregistrării",
  enterPasswordLabel: "Parola",
  enterPasswordForgot: "Ați uitat parola?",
  passwordPopupTitle: "Parolă incorectă",
  passwordPopupText:
    "Dvs. sau altcineva ați introdus o parolă incorectă de mai multe ori. V-am blocat contul din motive de securitate",
  passwordPopupBtn1: "Reveniți la ecranul de start",

  // Create password
  createPasswordTitle: "Creați o parolă nouă",
  createPasswordText:
    "Pentru numărul de telefon<br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Parola",
  createPasswordInput1Hint: "Includeți cel puțin 6 simboluri",
  createPasswordInput2Label: "Reintroduceți parola",

  // Registration
  registrationTitle: "Creați un cont",
  registrationText:
    "Pentru înregistrare, introduceți propriul număr de telefon și creați o parolă",
  registrationInput1Label: "Parola",
  registrationInput1Hint: "Trebuie să conțină cel puțin 6 simboluri",
  registrationInput2Label: "Reintroduceți parola",
  registrationPolicy: `Continuând, confirm că sunt familiarizat/ă cu <a class="text-primary" href="{link}" target="_blank">Politica de confidențialitate</a> și sunt de acord cu prelucrarea datelor mele personale`,
  registrationPolicy1: `Continuând, confirm că sunt familiarizat/ă cu `,
  registrationPolicy2: `Politica de confidențialitate`,
  registrationPolicy3: `și sunt de acord cu prelucrarea datelor mele personale`,
  registration: "",

  // Full name
  fullNameTitle: "Creați un profil",
  fullNameText: "Pentru a primi și trimite colete.",
  fullNameBtn: "Înregistrați-vă",
  fullNameFirst: "Prenumele",
  fullNameLast: "Numele de familie",
  fullNameMiddle: "Patronimicul",
  fullNameCheckbox: "Fără patronimic în documente",

  // Create recovery
  createRecoveryTitle: "Protejați-vă contul",
  createRecoveryText:
    "Completați unul din contactele suplimentare (e-mailul dvs. sau un număr de telefon suplimentar) pentru a vă restabili profilul mai rapid dacă este necesar.",
  createRecoveryHint:
    "Numărul de telefon nu trebuie să coincidă cu cel introdus la înregistrare",

  // Choose recovery
  chooseRecoveryTitle: "Restabilirea parolei",
  chooseRecoveryText: "Selectați un contact și așteptați un cod de confirmare",
  chooseRecoveryGreyBtn: "Nu pot obține codul",

  // Profile blocked
  profileBlockedTitle: "Accesul la aplicație este temporar restricționat",
  profileBlockedText:
    "Ați utilizat toate încercările de a introduce codul. Încercați din nou în 10 minute.",
  profileBlockedTimer:
    "Încercați din nou în: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Contul va fi deblocat în <span class='no-wrap text-black text-weight-medium'>{minutes} хв</span>",
  profileBlockedBtn: "Am înțeles",

  // Recovery not found
  recoveryNotFoundTitle: "Înregistrați un cont nou",
  recoveryNotFoundText1:
    "Nu ați introdus date de contact suplimentare, astfel nu vă putem restabili accesul la cont după număr <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Vă rugăm să vă înregistrați din nou cu același număr. Informațiile despre coletele dvs. vor rămâne, dar nu vom putea salva punctele acumulate",
  recoveryNotFoundBtn: "Înregistrare",
  recoveryNotNowBtn: "Nu acum",

  recoveryNotFoundText1Another:
    "Nu aveți acces la contactele dvs., respectiv nu vă putem restabili accesul la contul dvs. după număr <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Parola a fost schimbată",
  passwordChangedText1:
    "Folosiți parola numai pe site-ul web novapost.com și în aplicație.",
  passwordChangedText2:
    "Nu divulgați parola nimănui. Angajații noștri nu o întreabă niciodată",
  passwordChangedBtn: "Am înțeles",

  // Error limit 10
  errorLimit10PageTitle: "Numărul dvs. temporar a fost blocat",
  errorLimit10PageTextWithNumber:
    "Nu v-am putut verifica numărul prin cod sau din cauza unei conexiuni instabile, mesajele dvs. nu au fost livrate.",
  errorLimit10PageTimer:
    "Vă rugăm să încercați încă o dată prin <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Reveniți la ecranul de start",

  // Error Reg Limit
  errorRegLimitTitle: "Încercați mai târziu",
  errorRegLimitText:
    "Numărul de înregistrări pentru un număr de telefon este limitat. Vă rugăm să încercați să vă înregistrați peste 4 ore ",
  errorRegLimitBtn: "Reveniți la ecranul de start",

  // Error unforeseen
  errorUnforeseenTitle: "S-a produs o eroare",
  errorUnforeseenText: "Vă rugăm să încercați să vă conectați din nou",
  errorUnforeseenBtn: "Am înțeles",

  // Bot dialog
  botDialogTitle: "A apărut o eroare",
  botDialogText: "Vă rugăm să încercați din nou să vă autentificați",
  botDialogBtn: "Am înțeles",

  // Email for login not found
  emailNotFoundTitile:
    "Din păcate, nu s-a găsit niciun profil pentru această adresă de e-mail",
  emailNotFoundText: "Este necesară autentificarea prin numărul de telefon",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Această opțiune este disponibilă doar pentru reprezentanții persoanelor juridice",
  loginByEmailDeclinedText:
    "Este necesară autentificarea prin numărul de telefon",
};

export default ro;
