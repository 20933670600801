import { defineStore } from "pinia";
import countries from "@/helpers/countryList";
import { mask } from "maska";
import { isUkraine } from "../helpers";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    isUkraine: false,
    challenge: "",
    language: "",
    IsCba: false,
    isEmailAuth: false,
    countryCode: countries[0].label.slice(1),
    countryIcon: countries[0].icon,
    phoneNumber: "",
    email: "",
    selectedCode: null,
    customername: "",
    process: "login",
    registration: false,

    otptype: "",
    otpcode: "",
    otpsend: false,
    isRegistration: false,

    pincode: "",

    // needsetphone: false,
    // needsetpincode: false,

    docLanguage: "uk",
    lastName: "",
    firstName: "",
    middleName: "",
    pickedFuzzy: [-1, -1, -1],
    birthdayDate: null,

    RecoveryAccessMainPhone: "",
    RecoveryAccessMainEmail: "",
    RecoveryAccessParentsPhone: "",
    RecoveryAccessWorkPhone: "",
    RecoveryAccessWorkEmail: "",
    RecoveryAccessSpouseEmail: "",

    tempRegistration: false,
    tempProcess: "",

    recoveryname: "",
    recoveryType: "",
    recoveryList: {
      // RecoveryAccessMainEmail: "d***@s***.***",
      // RecoveryAccessParentsEmail: "i***@s***.***",
      // RecoveryAccessMainPhone: "**********12",
      // RecoveryAccessParentsPhone: "**********11",
      // RecoveryAccessWorkPhone: "**********13",
    },

    socialAuthData: {},
  }),
  getters: {
    phone: (state) => state.countryCode + state.phoneNumber,
    username: (state) => {
      return state.isEmailAuth
        ? state.email
        : state.countryCode + state.phoneNumber;
    },
    maskedPhone: (state) => {
      const selectedMask =
        state.countryCode +
        " " +
        countries.find((item) => item.label.slice(1) === state.countryCode)
          .mask;
      return mask(state.phone, selectedMask);
    },
  },
  actions: {
    increment() {},
  },
});
