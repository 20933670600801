import axios from "axios";

// const host = location.hostname.includes('localhost')? '/': 'https://id.novaposhta.ua/profile';
const host = "/";
const hostFuzzy = "https://fuzzyname.npu.np.work/api/v1/names";
const csrfToken = document.getElementsByName("authenticity.token")[0]
  ? document.getElementsByName("authenticity.token")[0].value
  : "";

const axiosInstance = axios.create({
  baseURL: host,
  method: "post",
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken,
    // 'Accept-Language': store.state.profile.language,
  },
});

export const fuzzyInstance = axios.create({
  baseURL: hostFuzzy,
  method: "post",
  timeout: 10000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
});

axiosInstance.interceptors.response.use(
  (config) => {
    if (config.data.error) {
      //////////
      const error = new Error(config.data.error);
      error.status = config.data.errorCode;
      error.redirect = config.data.redirect_to;
      error.attempts = config.data.attempts;
      // console.log("const error", error);
      return Promise.reject(error);
      //////////
    }
    return config;
  },
  (error) => {
    // console.log("interceptors catch error", error.toJSON());
    return Promise.reject(error.toJSON());
  }
);

export default axiosInstance;
