<template>
  <div class="content">
    <!-- <router-view /> -->
    <RouterView v-slot="{ Component }">
      <Transition mode="out-in">
        <Suspense v-if="Component">
          <template #default>
            <component :is="Component"></component>
          </template>
          <template #fallback>
            <p>{{ t("loading") }}</p>
          </template>
        </Suspense>
      </Transition>
    </RouterView>
  </div>
</template>

<script setup>
import apiInstance from "@/api/instances.js";
import BaseDialog from "@/components/ui/BaseDialog.vue";
import { RouterView } from "vue-router";
import { onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { useQuasar } from "quasar";
import { useRoute } from "vue-router";
import { useProfileStore } from "@/stores/profile";
import { useUiStore } from "@/stores/ui";
import { detectionBrowser } from "@/helpers";
import { storeToRefs } from "pinia";

const route = useRoute();
const $q = useQuasar();
const { t, locale } = useI18n({});
const profile = useProfileStore();
const ui = useUiStore();
const location = window.AppLocation;

$q.screen.setSizes({ md: 825, lg: 1280 });

const { isDarkMode } = storeToRefs(ui);

onMounted(() => {
  // Feature mode
  const feat = localStorage.getItem("npd-feature-mode");
  ui.featureMode = Boolean(feat);
  console.log("App mounted", ui.featureMode);

  // Challenge
  const timer = setInterval(() => {
    if (route.query.login_challenge) {
      profile.challenge = route.query.login_challenge;
      clearInterval(timer);
    }
  }, 100);

  // Language
  let lang = "";
  if (window.DefaultLang) {
    lang = window.DefaultLang;
  } else {
    lang = location === "ua" ? "uk" : "en";
  }

  locale.value = lang;
  profile.language = lang;
  apiInstance.defaults.headers.common["Accept-Language"] = lang;
  document.documentElement.lang = lang;
  if (location === "eu") document.title = "Nova Post";

  // Old browser
  let browser = detectionBrowser();
  if (
    (browser.name === "chrome" || browser.name === "crios") &&
    +browser.version < 67
  ) {
    let storeRedirect =
      browser.name === "chrome"
        ? "https://play.google.com/store/apps/details?id=com.android.chrome&gl=UA"
        : "https://apps.apple.com/ua/app/chrome-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80-%D0%BE%D1%82-google/id535886823";

    $q.dialog({
      component: BaseDialog,
      componentProps: {
        image: new URL("./assets/images/old-browser.png", import.meta.url).href,
        title: t("errorOldBrowserTitle"),
        text: [t("errorOldBrowserText")],
        persistent: true,
      },
    }).onOk(() => (window.location = storeRedirect));
  }

  // Theme
  isDarkMode.value = window.appearance === "dark";
  // isDarkMode.value = false;
  $q.dark.set(isDarkMode.value);
});
</script>

<style scoped>
.btn {
  font-weight: 500;
  font-size: 16px;
  text-transform: none;
}
.content {
  width: 100%;
}
</style>
