const uk = {
  accept: "Sapratu",
  next: "Turpināt",
  back: "Atpakaļ",
  skip: "Izlaist",
  language: "Valoda",
  loading: "Ielādē..",

  // Errors
  wrongRepeat: "Paroles nesakrīt",
  requireField: "Šis ir obligātais lauks",
  errorFioField: "Pārbaudiet, vai visi dati ir ievadīti pareizi",
  pinMust4: "Ir jāsatur vismaz 4 zīmes",
  pinMust6: "Ir jāsatur vismaz 6 zīmes",
  must4: "Ir jābūt 4 cipariem",
  must6: "Ir jābūt 6 cipariem",
  must9: "Ir jābūt 9 cipariem",
  protectionPhoneMainError: "Sakrīt ar galveno numuru",
  mustBe: "Ir jābūt ",
  mustBeEnd: " cipariem",
  unknownOperator: "Tikai mobilie numuri",
  contactRepeats: "Jaunais kontakts sakrīt ar iepriekš norādīto",
  errorOldBrowserTitle: "Atjauniniet pārlūkprogrammu",
  errorOldBrowserText:
    "Pašlaik jūs izmantojat novecojušu pārlūkprogrammas versiju. Lai turpinātu, lūdzu, atjauniniet pārlūkprogrammu",
  enterEmailError: "Nepareiza e-pasta adrese",
  errorPageTitle502: "Pakalpojums īslaicīgi nav pieejams",
  errorPageText502: "Mēģiniet pieslēgties nedaudz vēlāk",
  latNameError: "Lūdzu, aizpildiet latīņu alfabēta burtiem",
  wrongOtp: "Jūs ievadījāt nepareizu kodu. Palika {attempts} mēģinājumi(s)",
  wrongPassword:
    "Jūs ievadījāt nepareizu paroli. Palika {attempts} mēģinājumi(s)",

  // Phone input
  inputCountry: "Valsts",
  inputCountryName: "Valsts nosaukums",
  inputFound: "Meklēšanas rezultāti",
  inputNotFound:
    "Mums neizdevās neko atrast pēc pieprasījuma <span>”{text}”</span>",

  // Email input
  emailInputLabel: "E-pasts",

  // Start
  startTitle: "Laipni lūdzam Nova Post!",
  enterTitle: "Ievadiet savu tālruņa numuru",
  enterEmailTitle: "Ievadiet e-pasta adresi",
  enterAcceptBtn: "Nosūtīt kodu",
  enterText: "Varat pieteikties vai izveidot kontu, ja esat jauns Nova Post.",
  enterTextEmail: "Lai pierakstītos, ievadiet savu e-pasta adresi",
  enterOrRegistration: "Pieteikšanās",
  shouldRegistration:
    "Piesakieties sistēmā un saņemiet piekļuvi paplašinātiem iestatījumiem, pasūtiet papildu pakalpojumus un apmaksājiet tos tiešsaistē.",
  enterOr: "vai",
  enterPopupBtnEmail: "Pieteikties ar e-pastu",
  enterPopupBtnPhone: "Pieslēgties ar tālruņa numuru",

  // Social
  socialAppleBtn: "Turpināt ar Apple",
  socialGoogleBtn: "Turpināt ar Google",
  socialDeclinePopupTitle: "Autentifikācija nav iespējama",
  socialDeclinePopupText:
    "Dīvaini, bet šāda konta nav. Lūdzu, autentificējieties, izmantojot savu telefona numuru, un pēc tam pievienojiet savu Google vai Apple kontu profilā norādītajos iestatījumos.",
  socialDeclinePopupBtn: "Saprotams",

  // Enter Otp
  enterOtpTitle: "Ievadiet kodu no SMS",
  enterOtpTitleEmail: "Ievadiet kodu no e-pasta",
  enterOtpTextPhone:
    "Mēs esam nosūtījuši apstiprinājuma kodu uz numuru <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Mēs esam nosūtījuši apstiprinājuma kodu uz e-pastu <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle:
    "Ievadiet savu tālruņa numuru, lai pieteiktos, izmantojot {process}",
  enterOtpSocialText:
    "Tālruņa numurs ir nepieciešams pirmajai pieteikšanās reizei, izmantojot {process}, lai parādītu informāciju par jūsu sūtījumiem. Turpmākajās pieteikšanās reizēs nebūs nepieciešams ievadīt tālruņa numuru.",
  enterOtpCalling: "Mēs Tev zvanām",
  resendOtp: "Nosūtīt kodu vēlreiz",
  resendOtpByCall: "Labāk zvani",
  resendOtpAfter: "Nosūtīt vēlreiz pēc 0:{time}",
  enterOtp502Title: "Diemžēl, norādītais numurs neatbild",
  enterOtp502Text: "Iespējams, slikta savienojuma dēļ. Mēģiniet vēlreiz",

  // Enter password
  enterPasswordTitle: "Ievadiet paroli",
  enterPasswordText: "Šo paroli jūs norādījāt, reģistrējoties",
  enterPasswordLabel: "Parole",
  enterPasswordForgot: "Aizmirsāt paroli?",
  passwordPopupTitle: "Nepareiza parole",
  passwordPopupText:
    "Jūs vai kāds cits vairākas reizes ievadīja nepareizu paroli. Drošības dēļ mēs nobloķējām kontu.",
  passwordPopupBtn1: "Atgriezties sākumekrānā",

  // Create password
  createPasswordTitle: "Izveidojiet jaunu paroli",
  createPasswordText:
    "Numuram <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Parole",
  createPasswordInput1Hint: "Ir jāsatur vismaz 6 zīmes",
  createPasswordInput2Label: "Atkārtojiet paroli",

  // Registration
  registrationTitle: "Izveidot profilu",
  registrationText:
    "Lai reģistrētos, ievadiet savu tālruņa numuru un izveidojiet paroli",
  registrationInput1Label: "Parole",
  registrationInput1Hint: "Ir jāsatur vismaz 6 zīmes",
  registrationInput2Label: "Atkārtojiet paroli",
  registrationPolicy: `Turpinot, es apstiprinu, ka iepazinos ar <a class="text-primary" href="{link}" target="_blank">Privātuma politiku</a>, un piekrītu manu personas datu apstrādei`,
  registrationPolicy1: `Turpinot, es apstiprinu, ka iepazinos ar`,
  registrationPolicy2: `Privātuma politiku`,
  registrationPolicy3: `un piekrītu manu personas datu apstrādei`,
  registration: "",

  // Full name
  fullNameTitle: "Izveidojiet profilu",
  fullNameText: "Lai saņemtu un nosūtītu sūtījumus.",
  fullNameBtn: "Reģistrēties",
  fullNameFirst: "Vārds",
  fullNameLast: "Uzvārds",
  fullNameMiddle: "Tēvvārds",
  fullNameCheckbox: "Dokumentos nav norādīts tēvvārds",

  // Create recovery
  createRecoveryTitle: "Aizsargājiet savu kontu",
  createRecoveryText:
    "Aizpildiet vienu no papildu kontaktiem (jūsu e-pastu vai papildu numuru), lai nepieciešamības gadījumā varētu ātrāk atjaunot savu kontu.",
  createRecoveryHint:
    "Tālruņa numurs nedrīkst būt vienāds ar to, ko norādījāt reģistrējoties",

  // Choose recovery
  chooseRecoveryTitle: "Paroles atjaunošana",
  chooseRecoveryText: "Izvēlieties kontaktu un sagaidiet apstiprinājuma kodu",
  chooseRecoveryGreyBtn: "Nevaru saņemt kodu",

  // Profile blocked
  profileBlockedTitle: "Piekļuve lietotnei ir īslaicīgi ierobežota",
  profileBlockedText:
    "Jūs esat izsmēlis visas koda ievadīšanas mēģinājumus. Mēģiniet vēlreiz pēc 10 minūtēm.",
  profileBlockedTimer:
    "Mēģiniet vēlreiz pēc: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Konts tiks atbloķēts pēc: <span class='no-wrap text-black text-weight-medium'>{minutes} хв</span>",
  profileBlockedBtn: "Sapratu",

  // Recovery not found
  recoveryNotFoundTitle: "Reģistrējiet jaunu profilu",
  recoveryNotFoundText1:
    "Jūs neievadījāt papildu kontaktus, tāpēc mēs nevaram atjaunot piekļuvi jūsu profilam pēc numura: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Lūdzu, reģistrējieties atkārtoti ar šo pašu numuru. Informācija par jūsu sūtījumiem tiks saglabāta, taču uzkrātos punktus mēs saglabāt nevarēsim",
  recoveryNotFoundBtn: "Reģistrēties",
  recoveryNotNowBtn: "Vēlāk",

  recoveryNotFoundText1Another:
    "Jums nav piekļuves kontaktiem, tāpēc mēs nevaram atjaunot piekļuvi jūsu profilam pēc numura: <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Parole tika nomainīta",
  passwordChangedText1:
    "Izmantojiet šo paroli tikai vietnē novapost.com un lietotnē.",
  passwordChangedText2:
    "Nevienam neatklājiet šo paroli. Mūsu darbinieki nekad to nejautā",
  passwordChangedBtn: "Sapratu",

  // Error limit 10
  errorLimit10PageTitle: "Jūsu numurs uz laiku ir bloķēts",
  errorLimit10PageTextWithNumber:
    "Mums neizdevās apstiprināt jūsu numuru ar kodu vai arī nestabila savienojuma dēļ jūsu ziņojumi netika piegādāti.",
  errorLimit10PageTimer:
    "Lūdzu, mēģiniet vēlreiz pēc: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Atgriezties sākumekrānā",

  // Error Reg Limit
  errorRegLimitTitle: "Mēģiniet vēlāk",
  errorRegLimitText:
    "Reģistrāciju skaits vienam numuram ir ierobežots. Lūdzu, mēģiniet reģistrēties pēc 4 stundām",
  errorRegLimitBtn: "Atgriezties sākumekrānā",

  // Error unforeseen
  errorUnforeseenTitle: "Ir notikusi kļūda",
  errorUnforeseenText: "Atkārtojiet mēģinājumu, lai pieslēgtos",
  errorUnforeseenBtn: "Sapratu",

  // Bot dialog
  botDialogTitle: "Ir notikusi kļūda",
  botDialogText: "Atkārtojiet mēģinājumu, lai pieslēgtos",
  botDialogBtn: "Sapratu",

  // Email for login not found
  emailNotFoundTitile: "Diemžēl šim e-pasta adreses profils nav atrasts",
  emailNotFoundText: "Jums ir jāautentificējas, izmantojot tālruņa numuru",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Šī opcija ir pieejama tikai juridisko personu pārstāvjiem",
  loginByEmailDeclinedText:
    "Jums ir jāautentificējas, izmantojot tālruņa numuru",
};

export default uk;
