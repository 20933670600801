// const location = import.meta.env.VITE_LOCATION || "ua";
// const location = "eu";
const countries = [
  {
    icon: "uk",
    countryCode: "ua",
    iconUrl: new URL("@/assets/images/flags/uk.png", import.meta.url).href,
    flag: "🇺🇦",
    label: "+380",
    name: "Ukraine",
    nameUA: "Україна",
    value: 1,
    minLength: 9,
    maxLength: 9,
    length: "9",
    mask: "XX XXX XX XX",
    placeholder: "00 000 00 00",
  },
  {
    icon: "pl",
    countryCode: "pl",
    iconUrl: new URL("@/assets/images/flags/pl.png", import.meta.url).href,
    flag: "🇵🇱",
    label: "+48",
    name: "Poland",
    nameUA: "Польща",
    value: 0,
    minLength: 9,
    maxLength: 9,
    length: "9",
    mask: "XXX XXX XXX",
    placeholder: "000 000 000",
  },
  {
    icon: "lt",
    countryCode: "lt",
    iconUrl: new URL("@/assets/images/flags/lt.png", import.meta.url).href,
    flag: "🇱🇹",
    label: "+370",
    name: "Lithuania",
    nameUA: "Литва",
    value: 13,
    minLength: 8,
    maxLength: 9,
    length: "8-9",
    mask: "XXX XX XXXX",
    placeholder: "000 00 0000",
  },
  {
    icon: "ro",
    countryCode: "ro",
    iconUrl: new URL("@/assets/images/flags/ro.png", import.meta.url).href,
    flag: "🇷🇴",
    label: "+40",
    name: "Romania",
    nameUA: "Румунія",
    value: 3,
    minLength: 8,
    maxLength: 9,
    length: "9",
    mask: "XXXXXXXXX",
    placeholder: "000000000",
  },
  {
    icon: "cs",
    countryCode: "cz",
    iconUrl: new URL("@/assets/images/flags/cs.png", import.meta.url).href,
    flag: "🇨🇿",
    label: "+420",
    name: "Czech Republic",
    nameUA: "Чехія",
    value: 4,
    minLength: 9,
    maxLength: 9,
    length: "9",
    mask: "XXX XXX XXX",
    placeholder: "000 000 000",
  },
  {
    icon: "ml",
    countryCode: "md",
    iconUrl: new URL("@/assets/images/flags/ml.png", import.meta.url).href,
    flag: "🇲🇩",
    label: "+373",
    name: "Moldova",
    nameUA: "Молдова",
    value: 2,
    minLength: 8,
    maxLength: 8,
    length: "8",
    mask: "XXXXXXXX",
    placeholder: "00000000",
  },
  {
    icon: "de",
    countryCode: "de",
    iconUrl: new URL("@/assets/images/flags/de.png", import.meta.url).href,
    flag: "🇩🇪",
    label: "+49",
    name: "Germany",
    nameUA: "Німеччина",
    value: 9,
    minLength: 8,
    maxLength: 12,
    length: "8-12",
    mask: "XXXX XXXXXXXX",
    placeholder: "0000 00000000",
  },
  {
    icon: "ca",
    countryCode: "ca",
    iconUrl: new URL("@/assets/images/flags/ca.png", import.meta.url).href,
    flag: "🇨🇦",
    label: "+1",
    name: "Canada",
    nameUA: "Канада",
    value: 5,
    minLength: 10,
    maxLength: 10,
    length: "10",
    mask: "XXX XXX XXXX",
    placeholder: "000 000 0000",
  },
  {
    icon: "cn",
    countryCode: "cn",
    iconUrl: new URL("@/assets/images/flags/cn.png", import.meta.url).href,
    flag: "🇨🇳",
    label: "+86",
    name: "China",
    nameUA: "Китай",
    value: 6,
    minLength: 11,
    maxLength: 11,
    length: "11",
    mask: "XXX XXXX XXXX",
    placeholder: "000 0000 0000",
  },
  {
    icon: "ee",
    countryCode: "ee",
    iconUrl: new URL("@/assets/images/flags/ee.png", import.meta.url).href,
    flag: "🇪🇪",
    label: "+372",
    name: "Estonia",
    nameUA: "Естонія",
    value: 7,
    minLength: 7,
    maxLength: 8,
    length: "7-8",
    mask: "XXXX XXXX",
    placeholder: "0000 0000",
  },
  {
    icon: "fr",
    countryCode: "fr",
    iconUrl: new URL("@/assets/images/flags/fr.png", import.meta.url).href,
    flag: "🇫🇷",
    label: "+33",
    name: "France",
    nameUA: "Франція",
    value: 8,
    minLength: 9,
    maxLength: 9,
    length: "9",
    mask: "XXX XXX XXX",
    placeholder: "000 000 000",
  },
  {
    icon: "hu",
    countryCode: "hu",
    iconUrl: new URL("@/assets/images/flags/hu.png", import.meta.url).href,
    flag: "🇭🇺",
    label: "+36",
    name: "Hungary",
    nameUA: "Угорщина",
    value: 10,
    minLength: 9,
    maxLength: 9,
    length: "9",
    mask: "XX XXX XXXX",
    placeholder: "00 000 0000",
  },
  {
    icon: "it",
    countryCode: "it",
    iconUrl: new URL("@/assets/images/flags/it.png", import.meta.url).href,
    flag: "🇮🇹",
    label: "+39",
    name: "Italy",
    nameUA: "Італія",
    value: 11,
    minLength: 8,
    maxLength: 12,
    length: "8-12",
    mask: "XXX XXX XXX XXX",
    placeholder: "000 000 000 000",
  },
  {
    icon: "lv",
    countryCode: "lv",
    iconUrl: new URL("@/assets/images/flags/lv.png", import.meta.url).href,
    flag: "🇱🇻",
    label: "+371",
    name: "Latvia",
    nameUA: "Латвія",
    value: 12,
    minLength: 8,
    maxLength: 8,
    length: "8",
    mask: "XXX XX XXX",
    placeholder: "000 00 000",
  },
  {
    icon: "sk",
    countryCode: "sk",
    iconUrl: new URL("@/assets/images/flags/sk.png", import.meta.url).href,
    flag: "🇸🇰",
    label: "+421",
    name: "Slovakia",
    nameUA: "Словаччина",
    value: 14,
    minLength: 9,
    maxLength: 9,
    length: "9",
    mask: "XX XXX XXXX",
    placeholder: "00 000 0000",
  },
  {
    icon: "es",
    countryCode: "es",
    iconUrl: new URL("@/assets/images/flags/es.png", import.meta.url).href,
    flag: "🇪🇸",
    label: "+34",
    name: "Spain",
    nameUA: "Іспанія",
    value: 15,
    minLength: 9,
    maxLength: 9,
    length: "9",
    mask: "XXX XX XX XX",
    placeholder: "000 00 00 00",
  },
  {
    icon: "tr",
    countryCode: "tr",
    iconUrl: new URL("@/assets/images/flags/tr.png", import.meta.url).href,
    flag: "🇹🇷",
    label: "+90",
    name: "Turkey",
    nameUA: "Туреччина",
    value: 16,
    minLength: 10,
    maxLength: 10,
    length: "10",
    mask: "XXX XXX XX XX",
    placeholder: "000 000 00 00",
  },
  {
    icon: "en",
    countryCode: "gb",
    iconUrl: new URL("@/assets/images/flags/en.png", import.meta.url).href,
    flag: "🇬🇧",
    label: "+44",
    name: "United Kingdom",
    nameUA: "Велика Британія",
    value: 17,
    minLength: 9,
    maxLength: 10,
    length: "10",
    mask: "XXX XXXX XXXX",
    placeholder: "000 0000 0000",
  },
  {
    icon: "us",
    countryCode: "us",
    iconUrl: new URL("@/assets/images/flags/us.png", import.meta.url).href,
    flag: "🇺🇸",
    label: "+1",
    name: "USA",
    nameUA: "США",
    value: 18,
    minLength: 10,
    maxLength: 10,
    length: "10",
    mask: "XXX XXX XXXX",
    placeholder: "000 000 0000",
  },

  // { icon: 'au', label: '+43', name: 'Austria', value: 7,  minLength: 4, maxLength: 12,length: '4-12' },
  // { icon: 'be', label: '+32', name: 'Belgium', value: 8,  minLength: 8, maxLength: 12,length: '8-10' },
  // { icon: 'fi', label: '+358', name: 'Finland', value: 9, minLength: 5, maxLength: 12,length: '5-12' },
  // { icon: 'se', label: '+46', name: 'Sweden', value: 13,  minLength: 8, maxLength: 12,length: '6-9' },

  // { icon: 'ru', label: '+7', name: 'Russia', value: 10 },
];

export default countries;
