import { createRouter, createWebHistory } from "vue-router";
import { useProfileStore } from "@/stores/profile";
import countries from "@/helpers/countryList";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/HomePage.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/StartPage.vue"),
    },
    {
      path: "/enterUsername",
      name: "enterUsername",
      alias: [
        "/login/enterUsername",
        "/registration/enterUsername",
        "/re-registration/enterUsername",
        "/recovery/enterUsername",
      ],
      component: () => import("../views/EnterUsernamePage.vue"),
    },
    {
      path: "/enterSocialPhone",
      name: "enterSocialPhone",
      alias: ["/social/enterSocialPhone"],
      component: () => import("../views/EnterSocialPhonePage.vue"),
    },
    {
      path: "/enterOtp",
      alias: [
        "/login/enterOtp",
        "/registration/enterOtp",
        "/re-registration/enterOtp",
        "/recovery/enterOtp",
        "/social/enterOtp",
      ],
      name: "enterOtp",
      component: () => import("../views/EnterOtpPage.vue"),
    },
    {
      path: "/enterPassword",
      alias: ["/login/enterPassword", "/social/enterPassword"],
      name: "enterPassword",
      component: () => import("../views/EnterPasswordPage.vue"),
    },
    {
      path: "/createPassword",
      alias: [
        "/login/createPassword",
        "/recovery/createPassword",
        "/re-registration/createPassword",
      ],
      name: "createPassword",
      component: () => import("../views/CreatePasswordPage.vue"),
    },

    {
      path: "/registration",
      name: "registration",
      component: () => import("../views/RegistrationPage.vue"),
    },
    {
      path: "/fullName",
      alias: [
        "/login/fullName",
        "/registration/fullName",
        "/re-registration/fullName",
        "/social/fullName",
      ],
      name: "fullName",
      component: () => import("../views/FullNamePage.vue"),
    },
    {
      path: "/createRecovery",
      alias: [
        "/login/createRecovery",
        "/registration/createRecovery",
        "/re-registration/createRecovery",
      ],
      name: "createRecovery",
      component: () => import("../views/CreateRecoveryPage.vue"),
    },
    {
      path: "/chooseRecovery",
      alias: ["/recovery/chooseRecovery"],
      name: "chooseRecovery",
      component: () => import("../views/ChooseRecoveryPage.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const profile = useProfileStore();

  if (!from.name && to.name === "login") {
    profile.challenge = to.query.login_challenge;

    if (window.CountryCode) {
      const code = countries.find((item, i) => {
        if (window.CountryCode.toLowerCase() == item.countryCode) return item;
      });
      profile.$patch({
        countryCode: code?.label.slice(1) || countries[0].label.slice(1),
        countryIcon: code?.icon || countries[0].icon,
      });
      if (window.CountryCode.toLowerCase() == "ua")
        profile.$patch({ isUkraine: true });
    }

    if (window.LoginHint && window.LoginHint !== "38") {
      const code = countries.find((item, i) => {
        const regex = new RegExp(`^${item.label.slice(1)}`);
        if (regex.test(window.LoginHint)) return item;
      });
      profile.$patch({
        countryCode: code.label.slice(1),
        phoneNumber: window.LoginHint.slice(code.label.length - 1),
      });
    }

    // TODO: uncomment when roles will be available
    // if (window.IsCba) {
    //   profile.$patch({
    //     IsCba: true,
    //   });
    // }

    if (window.IsRegister) {
      profile.$patch({
        registration: true,
        process: "registration",
      });
      next(`/registration`);
    } else if (window.LoginHint && !window.IsRegister) next(`/login/enterOtp`);
    else next();
  }
  next();
});
// router.afterEach((to, from) => {
//   const profile = useProfileStore();
//   console.log("process", profile.process, "\nafterEach", to, from);
// });

export default router;
