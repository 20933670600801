import { createI18n } from "vue-i18n";
import uk from "@/locales/uk";
import pl from "@/locales/pl";
import en from "@/locales/en";
import lt from "@/locales/lt";
import de from "@/locales/de";
import cs from "@/locales/cs";
import it from "@/locales/it";
import ro from "@/locales/ro";
import hu from "@/locales/hu";
import et from "@/locales/et";
import sk from "@/locales/sk";
import lv from "@/locales/lv";
import fr from "@/locales/fr";
import es from "@/locales/es";
import tr from "@/locales/tr";

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: "uk", // set locale
  fallbackLocale: "en", // set fallback locale
  messages: { uk, pl, en, lt, de, cs, it, ro, hu, et, sk, lv, fr, es, tr },
});

export default i18n;
