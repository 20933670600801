const pl = {
  accept: "Rozumiem",
  next: "Przejdź dalej",
  back: "Wróć",
  skip: "Pomiń",
  language: "Język",
  loading: "Ładowanie...",

  // Errors
  wrongRepeat: "Hasła nie pasują do siebie",
  requireField: "To pole jest wymagane",
  errorFioField: "Sprawdź poprawność wprowadzonych danych",
  pinMust4: "Musi zawierać 4 lub więcej znaków",
  pinMust6: "Powinno zawierać 6 lub więcej znaków",
  must4: "Musi zawierać 4 cyfry",
  must6: "Musi zawierać 6 cyfr",
  must9: "Musi zawierać 9 cyfr",
  protectionPhoneMainError: "Jest taki sam jak numer podstawowy",
  mustBe: "Musi być",
  mustBeEnd: "cyfr",
  unknownOperator: "Tylko numery telefonów komórkowych",
  contactRepeats: "Nowy numer odpowiada podanemu wcześniej",
  errorOldBrowserTitle: "Zaktualizuj przeglądarkę",
  errorOldBrowserText:
    "Obecnie używasz starszej wersji przeglądarki. Zaktualizuj przeglądarkę, aby kontynuować",
  enterEmailError: "Błędny adres e-mail",
  errorPageTitle502: "Usługa tymczasowo niedostępna",
  errorPageText502: "Spróbuj połączyć się później",
  latNameError: "Proszę wypełnić literami alfabetu łacińskiego",
  wrongOtp: "Podany kod jest niewłaściwy. Pozostało {attempts} prób",
  wrongPassword: "Podane hasło jest niewłaściwe. Pozostało {attempts} prób",

  // Phone input
  inputCountry: "Państwo",
  inputCountryName: "Nazwa państwa",
  inputFound: "Wyniki wyszukiwania",
  inputNotFound: "Nie znaleźliśmy niczego dla <span>”{text}”</span>",

  // Email input
  emailInputLabel: "Email",

  // Start
  startTitle: "Witamy w Nova Post!",
  enterTitle: "Podaj numer telefonu",
  enterEmailTitle: "Podaj adres e-mail",
  enterAcceptBtn: "Wyślij kod",
  enterText:
    "Możesz się zalogować lub utworzyć konto, jeśli jesteś nowym użytkownikiem Nova Post.",
  enterTextEmail: "Aby się zalogować, wprowadź swój adres e-mail",
  enterOrRegistration: "Logowanie",
  shouldRegistration:
    "Zaloguj się i uzyskaj dostęp do zaawansowanych ustawień, zamów dodatkowe usługi i zapłać za nie online.",
  enterOr: "lub",
  enterPopupBtnEmail: "Zaloguj się za pomocą e-maila",
  enterPopupBtnPhone: "Zaloguj się numerem telefonu",

  // Social
  socialAppleBtn: "Kontynuuj z Apple",
  socialGoogleBtn: "Kontynuuj z Google",
  socialDeclinePopupTitle: "Nemôžem autorizovať",
  socialDeclinePopupText:
    "Ľutujeme, ale taký účet neexistuje. Prosím, autorizujte sa pomocou svojho telefónneho čísla a potom pridajte váš účet Google alebo Apple v nastaveniach profilu.",
  socialDeclinePopupBtn: "Rozumel",

  // Enter Otp
  enterOtpTitle: "Wpisz kod z SMS-a",
  enterOtpTitleEmail: "Wpisz kod z e-maila",
  enterOtpTextPhone:
    "Wysłaliśmy kod weryfikacyjny na numer <br /><span class='no-wrap text-black text-weight-medium white'>+{phone}</span>",
  enterOtpTextEmail:
    "Wysłaliśmy kod weryfikacyjny na email <br /><span class='no-wrap text-black text-weight-medium white'>{email}</span>",
  enterOtpSocialTitle:
    "Wprowadź swój numer telefonu, aby zalogować się za pomocą {process}",
  enterOtpSocialText:
    "Numer telefonu jest wymagany przy pierwszym logowaniu za pomocą {process}, aby wyświetlić informacje o Twoich przesyłkach. Przy kolejnych logowaniach nie będzie konieczne podawanie numeru telefonu.",
  enterOtpCalling: "Dzwonimy do Ciebie",
  resendOtp: "Wyślij kod ponownie",
  resendOtpByCall: "Lepiej zadzwoń",
  resendOtpAfter: "Wyślij ponownie za 0:{time}",
  enterOtp502Title: "Przepraszamy, ten numer nie odpowiada",
  enterOtp502Text: "Być może z powodu złego połączenia. Spróbuj ponownie",

  // Enter password
  enterPasswordTitle: "Wpisz hasło",
  enterPasswordText: "To jest hasło, które wprowadziłeś podczas rejestracji",
  enterPasswordLabel: "Hasło",
  enterPasswordForgot: "Zapomniałeś hasła?",
  passwordPopupTitle: "Błędne hasło",
  passwordPopupText:
    "Ty lub ktoś inny wielokrotnie wprowadził błędne hasło. Zablokowaliśmy Twój profil, aby Cię chronić.",
  passwordPopupBtn1: "Powrót do ekranu głównego",

  // Create password
  createPasswordTitle: "Utwórz nowe hasło",
  createPasswordText:
    "Dla numera <br /><span class='no-wrap text-black white'>+{phone}</span>",
  createPasswordInput1Label: "Hasło",
  createPasswordInput1Hint: "Powinno zawierać 6 lub więcej znaków",
  createPasswordInput2Label: "Powtórz hasło",

  // Registration
  registrationTitle: "Utwórz profil",
  registrationText:
    "Aby się zarejestrować, wprowadź swój numer telefonu i utwórz hasło",
  registrationInput1Label: "Hasło",
  registrationInput1Hint: "Powinno składać się przynajmniej z 6 znaków",
  registrationInput2Label: "Powtórz hasło",
  registrationPolicy: `Kontynuując, potwierdzam, że zapoznałe(a)m się z <a class="text-primary" href="{link}" target="_blank">Polityką Prywatności</a> i wyrażam zgodę na przetwarzanie moich danych osobowych`,
  registrationPolicy1: `Kontynuując, potwierdzam, że zapoznałe(a)m się z`,
  registrationPolicy2: `Polityką Prywatności`,
  registrationPolicy3: `i wyrażam zgodę na przetwarzanie moich danych osobowych`,
  registration: "",

  // Full name
  fullNameTitle: "Utwórz profil",
  fullNameText: "Aby odbierać i wysyłać paczki.",
  fullNameBtn: "Zarejestruj się",
  fullNameFirst: "Imię",
  fullNameLast: "Nazwisko",
  fullNameMiddle: "Imię ojca",
  fullNameCheckbox: "W dokumentach nie ma imienia ojca",

  // Create recovery
  createRecoveryTitle: "Chroń swój profil",
  createRecoveryText:
    "Wypełnij jeden z dodatkowych kontaktów (swój adres e-mail lub dodatkowy numer), aby jak najszybciej przywrócić swój profil.",
  createRecoveryHint:
    "Numer telefonu nie może być identyczny jak ten podany podczas rejestracji",

  // Choose recovery
  chooseRecoveryTitle: "Odzyskiwanie hasła",
  chooseRecoveryText: "Wybierz kontakt i czekaj na kod weryfikacyjny",
  chooseRecoveryGreyBtn: "Nie mogę uzyskać kodu",

  // Profile blocked
  profileBlockedTitle: "Dostęp do aplikacji tymczasowo ograniczony",
  profileBlockedText:
    "Wykorzystałeś wszystkie próby wpisania kodu. Spróbuj ponownie za 10 minut.",
  profileBlockedTimer:
    "Spróbuj ponownie za: <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  profileBlockedMinutes:
    "Profil odblokuje się za <span class='no-wrap text-black text-weight-medium'>{minutes} хв</span>",
  profileBlockedBtn: "Rozumiem",

  // Recovery not found
  recoveryNotFoundTitle: "Zarejestruj nowy profil",
  recoveryNotFoundText1:
    "Nie dodałeś żadnych dodatkowych kontaktów, więc nie możemy przywrócić Twojego dostępu do Twojego profilu w <span class='no-wrap text-black text-weight-medium'>+{phone}</span>",
  recoveryNotFoundText2:
    "Zarejestruj się ponownie pod tym samym numerem. Informacje o Twoich pakietach pozostaną, ale nie będziemy w stanie zapisać zgromadzonych punktów",
  recoveryNotFoundBtn: "Zarejestruj się",
  recoveryNotNowBtn: "Nie teraz",

  recoveryNotFoundText1Another:
    "Nie masz dostępu do swoich kontaktów, więc nie możemy przywrócić Ci dostępu do profilu za numerem <span class='no-wrap text-black text-weight-medium'>+{phone}</span>.",

  // Password changed
  passwordChangedTitle: "Hasło zostało zmienione",
  passwordChangedText1:
    "Używaj hasła tylko na stronie novapost.com i w aplikacji.",
  passwordChangedText2:
    "Nie podawaj nikomu hasła. Nasi pracownicy nigdy o to nie pytają",
  passwordChangedBtn: "Rozumiem",

  // Error limit 10
  errorLimit10PageTitle: "Twój numer został tymczasowo zablokowany",
  errorLimit10PageTextWithNumber:
    "Nie udało nam się zweryfikować Twojego numeru za pomocą kodu lub Twoje wiadomości nie zostały dostarczone z powodu niestabilnego połączenia.",
  errorLimit10PageTimer:
    "Spróbuj ponownie za <span class='no-wrap text-black text-weight-medium'>{time}</span>",
  errorLimit10PageBtn: "Powrót do ekranu głównego",

  // Error Reg Limit
  errorRegLimitTitle: "Spróbuj ponownie później",
  errorRegLimitText:
    "Liczba rejestracji na jeden numer jest ograniczona. Spróbuj zarejestrować się za 4 godziny.",
  errorRegLimitBtn: "Powrót do ekranu głównego",

  // Error unforeseen
  errorUnforeseenTitle: "Wystąpił błąd",
  errorUnforeseenText: "Spróbuj ponownie, aby się zalogować",
  errorUnforeseenBtn: "Rozumiem",

  // Bot dialog
  botDialogTitle: "Wystąpił błąd",
  botDialogText: "Proszę spróbować ponownie, aby się zalogować",
  botDialogBtn: "Rozumiem",

  // Email for login not found
  emailNotFoundTitile: "Niestety nie znaleziono profilu dla tego adresu e-mail",
  emailNotFoundText: "Konieczna jest autoryzacja za pomocą numeru telefonu",

  // Login by email declined
  loginByEmailDeclinedTitle:
    "Ta opcja jest dostępna tylko dla przedstawicieli osób prawnych",
  loginByEmailDeclinedText:
    "Konieczna jest autoryzacja za pomocą numeru telefonu",
};

export default pl;
